<template>
  <div v-if="agentId == 'new'">
    <AgentUpdate :agentId="agentId" @saveAgent="update"></AgentUpdate>
  </div>
  <div class="flip-card" v-else>
    <v-card color="grey lighten-4" flat tile class="ml-3 mr-3">
      <v-toolbar dense rounded outlined>
        <v-btn @click="reload" v-if="inEdit" plain>
          <v-icon>mdi-autorenew</v-icon>
          <span v-t="'reload'"></span>
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="openSyncOwnershipConfirm" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-key</v-icon>
            <span class="ml-1">{{ $t('b2bAgentsHeader') }}</span>
          </v-btn>
        </div>
        <div>
          <v-btn @click="showIamUserModal" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-account-multiple</v-icon>
            <span v-t="'users'"></span>
          </v-btn>
        </div>
        <div>
          <v-btn @click="update" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-pencil</v-icon>
            <span v-t="'update'"></span>
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>

    <v-dialog v-model="syncOwnershipConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <span>{{ $t('b2bAgentsHeader') }}</span>
        </v-card-title>
        <v-card-text class="black-text">
          <span>{{ $t('b2bAgentsMessage') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="cancelSyncOwnershipConfirm"><span>{{ $t('cancel') }}</span></v-btn>
          <v-btn color="primary" text @click="confirmSync"><span>{{ $t('confirm') }}</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="syncOwnershipConfirmResultDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <span>{{ $t('b2bAgentsHeader') }}</span>
        </v-card-title>
        <v-card-text>
          <div>{{ syncOwnershipResult }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSyncOwnershipConfirmResultDialog"><span>{{ $t('cancel') }}</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-8">
      <div class="scene scene--card">
        <div class="card" v-bind:class="{ flipme: cardOne == 'flipped' }">
          <div class="card__face card__face--front">
            <AgentView v-if="!inEdit" :agentId="agentId"></AgentView>
          </div>
          <div class="card__face card__face--back">
            <AgentUpdate v-if="!!inEdit" :agentId="agentId" @saveAgent="update"></AgentUpdate>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="1200"
        v-model="iamUserModalIsVisible"
        persistent
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="iamUserModalIsVisible = !iamUserModalIsVisible">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-t="'users'"></v-toolbar-title>
        </v-toolbar>
      </v-card>
      <IamUsersTable iamTraitName="Agent" :iamTraitCustomValue="iamTraitCustomValue"/>
    </v-dialog>
  </div>
</template>

<script>
import {get, call, sync} from "vuex-pathify";
import AgentUpdate from "@/components/view/AgentUpdate";
import AgentView from "../../components/view/AgentView";
import IamUserTable from "../../components/IamUsersTable";

export default {
  components: {
    AgentView,
    AgentUpdate,
    IamUserTable
  },
  props: ["agentId"],
  data: () => ({
    step: 1,
    inEdit: false,
    cardOne: "start",
    iamUserModalIsVisible: false,
    iamTraitCustomValue: "",
    syncOwnershipConfirmDialog: false,
    syncOwnershipConfirmResultDialog: false,
    syncOwnershipResult: ""
  }),
  methods: {
    redirectTo() {
        this.$router.push('/agents'); 
      },
    update: function () {
      this.inEdit = !this.inEdit;
      this.cardOne == "start" ? (this.cardOne = "flipped") : (this.cardOne = "start");
    },
    reload: function () {
      this.$window.location.reload();
    },
    showIamUserModal() {
      this.iamTraitCustomValue = this.agents.find(x => x.id == this.agentId)?.code || '';
      this.iamUserModalIsVisible = true;
    },
    openSyncOwnershipConfirm() {
      this.syncOwnershipConfirmDialog = true;
    },
    cancelSyncOwnershipConfirm() {
      this.syncOwnershipConfirmDialog = false;
    },
    confirmSync() {
      this.$store.dispatch("agents/apiAgentIdSyncOwnershipsToPrestashopB2BPatch", this.agentId)
          .then(result => {
            this.syncOwnershipResult = result;
            this.syncOwnershipConfirmResultDialog = true;
          })
          .catch(error => {
            console.error("Errore nel sync", error);
          });
      this.syncOwnershipConfirmDialog = false;
    },
    closeSyncOwnershipConfirmResultDialog() {
      this.syncOwnershipConfirmResultDialog = false;
    },
    addAgent: function() {
      // console.info(this.newAgent);   
    }
  },
  computed: {
    agents: get("agents/agents")
  }
};
</script>

<style>
.card {
  width: 100%;
  height: 100%;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  position: relative;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card__face--front {
  background: red;
}

.card__face--back {
  background: blue;
  transform: rotateY(180deg);
}

.flipme {
  transform: rotateY(180deg);
}

</style>

